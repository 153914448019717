import React from "react";
import ReactDOM from "react-dom/client";
// Bootstrap Styles - don't modify
import "bootstrap/dist/css/bootstrap.css";

import Home from "./views/Home/Home";
// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  return (
    <>
      <Home />
    </>
  );
};
root.render(<App />);
// FontAwesome library to execute throw all the platform
// library.add(fas, far);
