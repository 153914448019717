import { React, useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import emailjs from "@emailjs/browser";

import "./email.scss";

export const Email = ({
  closed,
  onUpdateClose,
  minimized,
  onUpdateMinimize,
}) => {
  const [emailState, setEmailState] = useState({
    closed: true,
    minimized: false,
    maximized: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeClosed = useCallback((status) => {
    onUpdateClose(status);
  });
  useEffect(() => {
    // if we received the closed true it means we need to open the email again
    if (!closed && emailState.closed) {
      setEmailState((prevState) => ({ ...prevState, closed: false }));
      handleChangeClosed(false);
    } else if (closed && !emailState.closed) {
      setEmailState((prevState) => ({ ...prevState, closed: true }));
      handleChangeClosed(true);
    }
  }, [closed, handleChangeClosed, emailState.closed]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeMinimize = useCallback((status) => {
    onUpdateMinimize(status);
  });

  useEffect(() => {
    // if we received the minimize true it means we need to open the email again
    if (!minimized && emailState.minimized) {
      setEmailState((prevState) => ({ ...prevState, minimized: false }));
      handleChangeMinimize(false);
    } else if (minimized && !emailState.minimized) {
      setEmailState((prevState) => ({ ...prevState, minimized: true }));
      handleChangeMinimize(true);
    }
  }, [minimized, handleChangeMinimize, emailState.minimized]);

  // Variables for the email
  const [sent, setSent] = useState(false);
  const [from_email, setFromEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  /**
   * Send Email
   * @param {*} e 
   */
  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_email: from_email,
      subject: subject,
      message: body,
    };

    // Let's use the emailjs and send the parameters
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        () => {
          // console.log("SUCCESS!");
          setSent(true);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  /**************************************
   * Email red button
   **************************************/
  const redBtnClick = () => {
    // Clean the data of the email
    setSent(false);
    setBody("");
    setFromEmail("");
    setSubject("");
    // Set closed
    setEmailState({ ...emailState, closed: true });
    handleChangeClosed(true);
  };

  /**************************************
   * Email yellow button
   **************************************/
  const yellowBtnClick = () => {
    setEmailState({ ...emailState, minimized: true });
    handleChangeMinimize(true);
  };

  /**************************************
   * Email green button
   **************************************/

  // Width and height of the email
  const [emailWidth, setEmailWidth] = useState(0);
  const [emailHeight, setEmailHeight] = useState(0);
  const [emailTransform, setEmailTransform] = useState("translate(-235px, 2px)");

  // Width and height of the screen
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    const screen = document.querySelector(".screen");
    setScreenWidth(screen.offsetWidth);
    setScreenHeight(screen.offsetHeight);

    const emailContainer = document.querySelector(".email-container");

    setEmailWidth(emailContainer.offsetWidth);
    setEmailHeight(emailContainer.offsetHeight);
    setEmailTransform(emailContainer.style.transform);
  }, []);

  const greenBtnCallback = () => {
    const emailContainer = document.querySelector(".email-container");
    const emailWrapperContainer = document.querySelector(".email-wrapper");

    if (!emailState.maximized) {
      // Setting the transform email to remember the position
      const actualEmailContainer = document.querySelector(".email-container");
      setEmailTransform(actualEmailContainer.style.transform);

      // Set the email container
      emailContainer.style.transform = "none"; // This is for the dragger
      emailContainer.style.height = screenHeight + "px";
      emailContainer.style.width = screenWidth + "px";
      emailContainer.style.position = "absolute";

      // Set the email wrapper container
      emailWrapperContainer.style.height = screenHeight + "px";
      emailWrapperContainer.style.width = screenWidth + "px";
      // Set the email state with maximized true
      setEmailState({ ...emailState, maximized: true });
    } else {
      // Set the email container
      emailContainer.style.transform = emailTransform; // Setting the old postiion of the email
      emailContainer.style.height = emailHeight + "px";
      emailContainer.style.width = emailWidth + "px";
      emailContainer.style.position = "relative";

      // Set the email wrapper container
      emailWrapperContainer.style.height = emailHeight + "px";
      emailWrapperContainer.style.width = emailWidth + "px";

      // Set the email state with maximized false
      setEmailState({ ...emailState, maximized: false });
    }
  };

  return (
    <div
      className={`email-wrapper ${
        emailState.maximized
          ? "maximized"
          : emailState.minimized
          ? "minimized"
          : !emailState.closed
          ? "open"
          : "closed"
      }`}
    >
      <div className="email-header">
        <div className="circle red" onClick={redBtnClick}></div>
        <div className="circle yellow" onClick={yellowBtnClick}></div>
        <div className="circle green" onClick={greenBtnCallback}></div>
        <div className="email-title">E-mail</div>
      </div>
      {sent ? (
        <div className="email-sent">
          The message has been sent successfully.
          <br />
          Thank you very much for using Tomás Malio's private <b>messaging service</b> 😉.
        </div>
      ) : (
        <form onSubmit={sendEmail}>
          <div className="field">
            <label htmlFor="to">From:</label>
            <input
              type="email"
              id="from_email"
              name="from_email"
              autocomplete="off"
              value={from_email}
              onChange={(e) => setFromEmail(e.target.value)}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="to">To:</label>
            <span className="email-to">Tomás Malio</span>
          </div>
          <div className="field">
            <label htmlFor="subject">Asunto:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="body">
            <ReactQuill onChange={(e) => setBody(e)} />
          </div>
          <input name="submit" type="submit" value="Send" />
        </form>
      )}
    </div>
  );
};
