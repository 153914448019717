import { React } from "react";
import "./quote.scss";
export const Quote = () => {
  return (
    <section className="quote">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="text">
              Understanding of the problem 🤯, creative thinking 💡, planning and estimation 🗓️, programming
              and documentation 💻, implementation 🚀.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
