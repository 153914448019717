import { React, useEffect, useState, useCallback } from "react";
import Terminal, { ColorMode, TerminalOutput } from "react-terminal-ui";

import "./terminalsimulation.scss";

export const TerminalSimulation = ({ closed, onUpdateClose, minimized, onUpdateMinimize }) => {
  const [prompt, setPromt] = useState("terminal:/");
  const [basePrompt] = useState("terminal:/");
  const [colorMode] = useState(ColorMode.Dark);

  /**
   * Remove path for promt
   * 
   * @param {*} folder 
   * @param {*} inputString 
   * @returns String
   */
  const removePathAfter = (folder, inputString) => {
    const regex = new RegExp(`/${folder}(/|$)`);
    const match = inputString.match(regex);

    if (match) {
      const index = match.index + match[0].length - 1;
      return inputString.substring(0, index);
    }

    return inputString;
  }

  const [terminalState, setTerminalState] = useState({
    closed: false,
    minimized: false,
    maximized: false,
    history: [],
    files: [],
    directories: [{ name: "root", parent: null }], // Initialize directories with 'root'
    currentDirectory: "root", // Add 'currentDirectory' to track the current directory
    output: [
      "Welcome! Let's play with a dynamic terminal that allows",
      "you to use some typical commands 🚀.",
      "Type 'help' to see the list of available commands."
    ], // Initialize an empty array
  });

  // const [prevOpenTerminal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeClosed = useCallback((status) => {
    onUpdateClose(status);
  });
  useEffect(() => {
    // if we received the closed true it means we need to open the terminal again
    if (!closed && terminalState.closed) {
      setTerminalState((prevState) => ({ ...prevState, closed: false }));
      handleChangeClosed(false);
    } else if (closed && !terminalState.closed) {
      setTerminalState((prevState) => ({ ...prevState, closed: true }));
      handleChangeClosed(true);
    }
  }, [closed, handleChangeClosed, terminalState.closed]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeMinimize = useCallback((status) => {
    onUpdateMinimize(status);
  });
  useEffect(() => {
    // if we received the minimize true it means we need to open the terminal again
    if (!minimized && terminalState.minimized) {
      setTerminalState((prevState) => ({ ...prevState, minimized: false }));
      handleChangeMinimize(false);
    } else if (minimized && !terminalState.minimized) {
      setTerminalState((prevState) => ({ ...prevState, minimized: true }));
      handleChangeMinimize(true);
    }
  }, [minimized, handleChangeMinimize, terminalState.minimized]);

  /**
   * command: help
   */
  const handleHelp = () => {
    const commands = [
      {
        name: "ls",
        description: "Lists files and directories in a specific location on the file system.\n",
      },
      { name: "mkdir", description: "Creates a new directory. Example: mkdir test\n" },
      { name: "cd", description: "Allows you to move between system directories.\n" },
      {
        name: "touch",
        description:
          "Creates empty files and changes timestamps of files. Example: touch test.txt\n",
      },
      {
        name: "echo",
        description:
          'Displays the text or string you want. Examples: echo "Hello world!" or\n        echo "Hello world!" > filename.txt\n'
      },
      {
        name: "cat",
        description:
          "Prints the content of a file onto the standard output. Example: cat filename.txt\n",
      },
      { name: "clear", description: "Clears the screen.\n" },
      {
        name: "who",
        description: "Prints my information.\n",
      },
    ];
    setTerminalState((prevState) => ({
      ...prevState,
      history: [...prevState.history, "help"],
      //   output: [...prevState.output, "Available commands:"],
      output: [
        ...prevState.output,
        `${prompt} $ help`,
        commands.map((command) => `- ${command.name}: ${command.description}`),
      ],
    }));
  };

  /**
   * command: cd
   * @param {*} directoryName
   */
  const handleCd = (directoryName) => {
    // If the directory name is define
    if (directoryName) {
      // Going to a directory before
      if (directoryName === "..") {
        const foundDirectoryParent = terminalState.directories.find(
          (dir) => dir.name === terminalState.currentDirectory && dir.parent !== null
        );

        // If the directory contains a parent
        if (foundDirectoryParent) {
          // Create the new prompt
          const newPrompt = removePathAfter(foundDirectoryParent.parent, prompt);
          // Checking we're not in the root directory
          foundDirectoryParent.parent === "root"
            ? setPromt(`${basePrompt}`)
            : setPromt(`${newPrompt}`);

          setTerminalState((prevState) => ({
            ...prevState,
            history: [...prevState.history, `cd ${directoryName}`],
            currentDirectory: foundDirectoryParent.parent,
            output: [...prevState.output, `${newPrompt} $ cd ${directoryName}`],
          }));
        }
      } else {
        const foundDirectory = terminalState.directories.find(
          (dir) =>
            dir.name === directoryName &&
            (dir.parent === terminalState.currentDirectory || dir.parent === null)
        );

        // If the directory exists
        if (foundDirectory) {
          // Create the new prompt
          prompt === basePrompt
            ? setPromt(`${prompt}${foundDirectory.name}`)
            : setPromt(`${prompt}/${foundDirectory.name}`);

          setTerminalState((prevState) => ({
            ...prevState,
            history: [...prevState.history, `cd ${directoryName}`],
            currentDirectory: foundDirectory.name,
            output: [...prevState.output, `${prompt} $ cd ${foundDirectory.name}/`],
          }));
        } else {
          setTerminalState((prevState) => ({
            ...prevState,
            history: [...prevState.history, `cd ${directoryName}`],
            output: [
              ...prevState.output,
              `${prompt} $ cd ${directoryName}`,
              `'${directoryName}' not found.`,
            ],
          }));
        }
      }
    }
    // The directory name is not define
    else {
      emptyCommand("cd");
    }
  };

  /**
   * command: ls
   */
  const handleLs = () => {
    const currentDirectory = terminalState.currentDirectory;
    const files = terminalState.files.filter((file) => file.parent === currentDirectory);
    const directories = terminalState.directories.filter((dir) => dir.parent === currentDirectory);
    const output = [
      ...directories.map((dir) => `${dir.name}/`), // Display directories with '[DIR]' prefix
      ...files.map((file) => file.name),
    ];
    setTerminalState((prevState) => ({
      ...prevState,
      history: [...prevState.history, "ls"],
      output: [...prevState.output, `${prompt} $ ls`, ...output],
    }));
  };

  /**
   * command: mkdir
   * @param {*} directoryName
   */
  const handleMkdir = (directoryName) => {
    // If the directory is pass as argument
    if (directoryName) {
      const newDirectory = { name: directoryName, parent: terminalState.currentDirectory };
      setTerminalState((prevState) => ({
        ...prevState,
        history: [...prevState.history, `mkdir ${directoryName}`],
        directories: [...prevState.directories, newDirectory],
        output: [...prevState.output, `${prompt} $ mkdir ${directoryName}`],
      }));
    }
    // When the directory to create is not set
    else {
      emptyCommand("mkdir");
    }
  };

  /**
   * command: touch
   * @param {*} fileName
   */
  const handleTouch = (fileName) => {
    if (fileName) {
      const newFile = { name: fileName, parent: terminalState.currentDirectory };
      const existingFile = terminalState.files.find(
        (file) => file.name === fileName && file.parent === terminalState.currentDirectory
      );

      // We create the file
      if (!existingFile) {
        setTerminalState((prevState) => ({
          ...prevState,
          history: [...prevState.history, `touch ${fileName}`],
          files: [...prevState.files, newFile],
          output: [
            ...prevState.output,
            `${prompt} touch ${fileName}`,
            `File '${fileName}' created.`,
          ],
        }));
      }
      // If the file exists
      else {
        setTerminalState((prevState) => ({
          ...prevState,
          history: [...prevState.history, `touch ${fileName}`],
          output: [
            ...prevState.output,
            `${prompt} touch ${fileName}`,
            `File '${fileName}' already exists.`,
          ],
        }));
      }
    }
    // When we don't received the name of the filename
    else {
      emptyCommand("touch");
    }
  };

  /**
   * Who details
   */
  const userData = {
    name: "Tomás Malio",
    email: "tomasmalio@gmail.com",
  };

  /**
   * command: who
   */
  const handleWho = () => {
    setTerminalState((prevState) => ({
      ...prevState,
      history: [...prevState.history, "who"],
      output: [...prevState.output, `${prompt} who`, `${userData.name} <${userData.email}>`],
    }));
  };

  /**
   * command: ping
   * @param {*} hostname
   */
  const handlePing = (hostname) => {
    // Simulate a ping response
    const simulatedResponse = `PING ${hostname} (127.0.0.1): 56(84) bytes of data.\n64 bytes from localhost (127.0.0.1): icmp_seq=1 ttl=64 time=0.703 ms\n64 bytes from localhost (127.0.0.1): icmp_seq=2 ttl=64 time=0.698 ms`;

    setTerminalState((prevState) => ({
      ...prevState,
      history: [...prevState.history, `ping ${hostname}`],
      output: [...prevState.output, `${prompt} ping ${hostname}`, simulatedResponse],
    }));
  };

  /**
   * command: echo
   * @param {*} text
   */
  const handleEcho = (input) => {
    // Regex to detect the echo "string" >> filename
    const regex = /echo (.*?) (?:>|>>) (.*)/;

    // Getting the match information
    const match = input.match(regex);

    // If exits a string to save inside the file
    if (match) {
      const text = match[1].replace(/^"|"$|"/g, "");
      const fileName = match[2];
      const fileContent = text.trim();

      const existingFile = terminalState.files.find(
        (file) => file.name === fileName && file.parent === terminalState.currentDirectory
      );

      if (existingFile) {
        // Update existing file content
        setTerminalState((prevState) => ({
          ...prevState,
          history: [...prevState.history, `echo "${text}" > ${fileName}`],
          output: [...prevState.output, `${prompt} echo "${text}" > ${fileName}`],
          files: terminalState.files.map((file) =>
            file.name === fileName && file.parent === terminalState.currentDirectory
              ? {
                  ...file,
                  content: file.content ? `${file.content}\n${fileContent}` : `${fileContent}`,
                } // Append new line and text
              : file
          ),
        }));
        return;
      }

      // Create new file
      setTerminalState((prevState) => ({
        ...prevState,
        history: [...prevState.history, `echo "${text}" >> "${fileName}"`],
        output: [...prevState.output, `${prompt} echo "${text}" >> "${fileName}"`],
        files: [
          ...prevState.output,
          { name: fileName, parent: terminalState.currentDirectory, content: fileContent },
        ],
      }));
    } else {
      // Remove the echo from the beginning
      const text = input.slice(5);

      // Validate that we've arguments
      if (text) {
        // Handle regular echo command
        setTerminalState((prevState) => ({
          ...prevState,
          history: [...prevState.history, `echo ${text}`],
          output: [...prevState.output, `${prompt} echo ${text}`, text],
        }));
      } else {
        emptyCommand("echo");
      }
    }
  };

  /**
   * command: cat
   * @param {*} fileName
   */
  const handleCat = (fileName) => {
    if (fileName) {
      const existingFile = terminalState.files.find(
        (file) => file.name === fileName && file.parent === terminalState.currentDirectory
      );

      if (existingFile) {
        setTerminalState((prevState) => ({
          ...prevState,
          history: [...prevState.history, `cat ${fileName}`],
          output: [...prevState.output, `${prompt} cat ${fileName}`, existingFile.content],
        }));
      } else {
        setTerminalState((prevState) => ({
          ...prevState,
          history: [...prevState.history, `cat ${fileName}`],
          output: [
            ...prevState.output,
            `${prompt} cat ${fileName}`,
            `File '${fileName}' not found.`,
          ],
        }));
      }
    } else {
      emptyCommand("cat");
    }
  };

  /**
   * Empty command to set the problem of no arguments
   * @param {*} command
   */
  const emptyCommand = (command) => {
    setTerminalState((prevState) => ({
      ...prevState,
      history: [...prevState.history, `${command}`],
      output: [...prevState.output, `${prompt} $ ${command} `, `'${command}' without arguments.`],
    }));
  };
  /**
   * command: clear
   */
  const handleClear = () => {
    setTerminalState((prevState) => ({
      ...prevState,
      history: [], // Clear the history array
      output: [], // Clear the output array
    }));
  };

  /**
   * Unknown commands
   * @param {*} unknownCommand
   */
  const handleUnknownCommand = (unknownCommand) => {
    let print = "";

    // Let's create a good message for some commands
    switch (unknownCommand) {
      case "reboot":
        print = `Rebooting process...`;
        break;
      case "halt":
        print = `Turning off the computer 😧`;
        break;
      case "rm -rf *":
      case "rm *":
      case "delete":
      case "erease":
        print = `Hey! Be careful you're trying to erase me...`;
        break;
      default:
        print = `'${unknownCommand}' command not exists.`;
    }

    setTerminalState((prevState) => ({
      ...prevState,
      history: [...prevState.history, `${unknownCommand}`],
      output: [...prevState.output, `${prompt} $ ${unknownCommand} `, print],
    }));
  };

  /**
   * Handle commands
   * @param {*} input
   */
  const handleInput = (input) => {
    const [command, ...args] = input.split(" ");
    switch (command) {
      case "help":
        handleHelp();
        break;
      case "ls":
        handleLs();
        break;
      case "mkdir":
        if (args.length === 1) {
          handleMkdir(args[0]);
        } else {
          handleMkdir(null);
        }
        break;
      case "cd":
        if (args.length === 1) {
          handleCd(args[0]);
        } else {
          handleCd(null);
        }
        break;
      case "touch":
        if (args.length === 1) {
          handleTouch(args[0]);
        } else {
          handleTouch(null);
        }
        break;
      case "who":
        handleWho();
        break;
      case "ping":
        const hostname = input.substring(5); // Extract hostname after "ping "
        handlePing(hostname);
        break;
      case "echo":
        handleEcho(input);
        break;
      case "cat":
        if (args.length === 1) {
          handleCat(args[0]);
        } else {
          handleCat(null);
        }
        break;
      case "clear":
        handleClear();
        break;
      case "exit":
      case "quit":
        redBtnClick();
        handleClear();
        break;
      default:
        handleUnknownCommand(input);
        break;
    }
  };

  /**************************************
   * Terminal red button
   **************************************/
  const redBtnClick = () => {
    setTerminalState({ ...terminalState, closed: true });
    handleChangeClosed(true);
  };

  /**************************************
   * Terminal yellow button
   **************************************/
  const yellowBtnClick = () => {
    setTerminalState({ ...terminalState, minimized: true });
    handleChangeMinimize(true);
  };

  /**************************************
   * Terminal green button
   **************************************/
  // Width and height of the terminal
  const [terminalWidth, setTerminalWidth] = useState(0);
  const [terminalHeight, setTerminalHeight] = useState(0);
  const [terminalTransform, setTerminalTransform] = useState("none");

  // Width and height of the screen
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    const screen = document.querySelector(".screen");
    setScreenWidth(screen.offsetWidth);
    setScreenHeight(screen.offsetHeight);

    const terminalContainer = document.querySelector(".terminal-container");
    setTerminalWidth(terminalContainer.offsetWidth);
    setTerminalHeight(terminalContainer.offsetHeight);
    setTerminalTransform(terminalContainer.style.transform);
  }, []);

  const greenBtnClick = () => {
    const terminalContainer = document.querySelector(".terminal-container");
    const terminalWrapperContainer = document.querySelector(".react-terminal-wrapper");

    if (!terminalState.maximized) {
      // Setting the transform terminal to remember the position
      const actualTerminalContainer = document.querySelector(".terminal-container");
      setTerminalTransform(actualTerminalContainer.style.transform);

      // Set the terminal container
      terminalContainer.style.transform = "none"; // This is for the dragger
      terminalContainer.style.height = screenHeight + "px";
      terminalContainer.style.width = screenWidth + "px";

      // Set the terminal wrapper container
      terminalWrapperContainer.style.height = screenHeight + "px";
      terminalWrapperContainer.style.width = screenWidth + "px";

      // Set the terminal state with maximized true
      setTerminalState({ ...terminalState, maximized: true });
    } else {
      // Set the terminal container
      terminalContainer.style.transform = terminalTransform; // Setting the old postiion of the terminal
      terminalContainer.style.height = terminalHeight + "px";
      terminalContainer.style.width = terminalWidth + "px";

      // Set the terminal wrapper container
      terminalWrapperContainer.style.height = terminalHeight + "px";
      terminalWrapperContainer.style.width = terminalWidth + "px";

      // Set the terminal state with maximized false
      setTerminalState({ ...terminalState, maximized: false });
    }
  };

  return (
    <div>
      {/* <button className={btnClasses.join(" ")} onClick={toggleColorMode}>
            Enable {colorMode === ColorMode.Light ? "Dark" : "Light"} Mode
          </button> */}
      {terminalState.closed ? null : (
        <>
          {terminalState.minimized ? null : (
            <Terminal
              name="Terminal"
              closed={terminalState.closed}
              minimized={terminalState.minimized}
              colorMode={colorMode}
              onInput={handleInput}
              startingInputValue=""
              initialPosition="top"
              prompt={`${prompt} $`}
              redBtnCallback={redBtnClick}
              yellowBtnCallback={yellowBtnClick}
              greenBtnCallback={greenBtnClick}
            >
              {terminalState.output.length > 0
                ? terminalState.output?.map((line) => (
                    <TerminalOutput key={line}>{line}</TerminalOutput>
                  ))
                : ""}
            </Terminal>
          )}
        </>
      )}
    </div>
  );
};
