import { React, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { WorkingProfile } from "../WorkingProfile/WorkingProfile";

import "../../assets/scss/styles.scss";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLin';

import { Quote } from "../Quote/Quote";
import { Notebook } from "../Notebook/Notebook";
// import { Technologies } from "../Technologies/Technologies";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [theme, setTheme] = useState("light");

  const prefersDarkTheme = window.matchMedia("(prefers-color: dark)").matches;

  useEffect(() => {
    setTheme(prefersDarkTheme ? "dark" : "");
  }, [prefersDarkTheme]);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight - 100,
      });
    }

    window.addEventListener("resize", handleResize);

    // Calling the handleResize to set the initial size
    handleResize();

    // Remove event listener when unmounting component
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Asegúrate de que la página esté en la parte superior al cargar
    window.scrollTo(0, 0);
  }, []);

  // const handleClick = () => {
  //   setShowModal((prev) => !prev); // Cambia el estado del modal basado en el estado anterior
  //   console.log("Modal state:", showModal);
  // };

  return (
    <div className={theme === "light" ? "" : "dark-theme"}>
      <Helmet>
        <title>Tomás Malio - IT Engineer and Tech Entrepreneur</title>
        <meta
          name="description"
          content="Since 2005 developing technological products for different types of companies."
        />
      </Helmet>
      {/* <div className="corner" onClick={handleClick}>
        a
      </div> */}

      <div className="theme-changer" onClick={() => setTheme(theme === "light" ? "dark" : "light")}>
        <span>{theme === "light" ? "Dark" : "Light"}</span>
        <FontAwesomeIcon icon={theme === "light" ? faMoon : faSun} />
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              x
            </span>
            <p>Contenido del Modal de Contacto</p>
          </div>
        </div>
      )}

      <section className="home" style={{ width: windowSize.width, height: windowSize.height }}>
        <div className="center-flex">
          <div className="">
            <h1>Tomás Malio</h1>
            <p className="personal-description">
              I <del>develop</del> <span>create software</span>.
            </p>
          </div>
        </div>
      </section>
      <WorkingProfile />
      <Quote />
      <Notebook />
      {/* <Technologies /> */}
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="line-divider"></div>
            </div>
            <div className="col-12">
              <a href="https://www.linkedin.com/in/tomasmalio" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
