import { React, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

import { TerminalSimulation } from "../TerminalSimulation/TerminalSimulation";
import { Email } from "../Email/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTerminal, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "./notebook.scss";

export const Notebook = () => {
  const [closedTerminal, setClosedTerminal] = useState(false);
  const [minimizedTerminal, setMinimizedTerminal] = useState(false);

  const handleCloseTerminal = (status) => {
    setClosedTerminal(status);
  };

  const handleMinimizeTerminal = (status) => {
    setMinimizedTerminal(status);
  };

  // For the position of the terminal
  useEffect(() => {
    const scrollPosition = window.scrollY;
    return () => {
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  const [closedEmail, setClosedEmail] = useState(true);
  const [minimizedEmail, setMinimizedEmail] = useState(false);

  const handleCloseEmail = (status) => {
    console.log("close notebook", status);
    setClosedEmail(status);
  };

  const handleMinimizeEmail = (status) => {
    console.log("mini notebook", status);
    setMinimizedEmail(status);
  };

  // Email
  const draggableRef = useRef(null);

  const handleMouseDown = (event) => {
    // Si el clic ocurre dentro de React Quill, cancelamos el arrastre
    if (event.target.closest(".ql-editor")) {
      if (draggableRef.current) {
        draggableRef.current.state.dragging = false;
      }
    }
  };
  return (
    <section className="notebook-container">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="notebook">
              <div className="screen">
                <Draggable bounds="parent" defaultPosition={{ x: 80, y: 5 }}>
                  <div className="terminal-container">
                    <TerminalSimulation
                      closed={closedTerminal}
                      onUpdateClose={handleCloseTerminal}
                      minimized={minimizedTerminal}
                      onUpdateMinimize={handleMinimizeTerminal}
                    />
                  </div>
                </Draggable>
                <Draggable
                  bounds="parent"
                  ref={draggableRef}
                  onMouseDown={handleMouseDown}
                  defaultPosition={{ x: -90, y: 8 }}
                >
                  <div className="email-container">
                    <Email
                      closed={closedEmail}
                      onUpdateClose={handleCloseEmail}
                      minimized={minimizedEmail}
                      onUpdateMinimize={handleMinimizeEmail}
                    />
                  </div>
                </Draggable>

                {closedTerminal === true ? (
                  <div className={"icon-terminal"} onClick={() => handleCloseTerminal(false)}>
                    <FontAwesomeIcon icon={faTerminal} />
                  </div>
                ) : minimizedTerminal === true ? (
                  <div className={"icon-terminal"} onClick={() => handleMinimizeTerminal(false)}>
                    <FontAwesomeIcon icon={faTerminal} />
                    <p className="text-icon-terminal">Terminal</p>
                  </div>
                ) : (
                  <div
                    className={"icon-terminal active"}
                    onClick={() => handleMinimizeTerminal(true)}
                  >
                    <FontAwesomeIcon icon={faTerminal} />
                  </div>
                )}

                {/* {minimizedTerminal === true ? (
                  <div
                    className={"icon-terminal" + (minimizedTerminal ? " active" : "")}
                    onClick={() => handleMinimizeTerminal(false)}
                  >
                    <FontAwesomeIcon icon={faTerminal} />
                    <p className="text-icon-terminal">Terminal</p>
                  </div>
                ) : (
                  <div
                    className={"icon-terminal" + (closedTerminal ? " active" : "")}
                    onClick={() => handleMinimizeTerminal(false)}
                  >
                    <FontAwesomeIcon icon={faTerminal} />
                  </div>
                )} */}
                {closedEmail === true ? (
                  <div className={"icon-email"} onClick={() => handleCloseEmail(false)}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                ) : minimizedEmail === true ? (
                  <div className={"icon-email"} onClick={() => handleMinimizeEmail(false)}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                ) : (
                  <div className={"icon-email active"} onClick={() => handleMinimizeEmail(true)}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                )}
              </div>
            </div>
            <div className="notebook-footer">
              <div className="rectangle"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
