import { React, useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

// Styles
import "./workingprofile.scss";

// Logo images
import apmterminals from "./images/apmterminals_logo.png";
import covrel from "./images/covrel_logo.png";
import repeat from "./images/repeat_logo.jpeg";
import plugty from "./images/plugty_logo.jpeg";
import topsifytrends from "./images/topsifytrends_logo.png";
import eventdoo from "./images/eventdoo_logo.png";

export const WorkingProfile = () => {
  const [codeString, setCodeString] = useState("");

  useEffect(() => {
    setCodeString(`import { useEffect, useState } from "react";
import { getWorkinghData } from 'controllers';
// Components
import { PersonalInformation } from "./Components/PersonalInformation";
import { WorksDetails } from "./Components/WorksDetails";

// WorkingProfile view
export const WorkingProfile = () => {
      const [loading, setLoading] = useState(true);
      const [working, setWorking] = useState(null);
      
      // Fetch data of the working experience
      const fetchData = async () => {
        try {
          // Calling the endpoint to get the data
          let data = await getWorkinghData('working-experience');

          // Check if there's data and set the working variable
          if (data) {
            setWorking(data);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error to fetch working data', error);
        }
      }

      // Load the content
      useEffect(() => {
        fetchData();
      }, []);
      
      return (
        <div className="content">
          <PersonalInformation />
          <WorksDetails data={working} status={loading} />
        </div>
      );
}`);
  }, []);

  return (
    <section className="working-profile">
      <div className="container">
        <div className="row">
          <div className="col-md-5 d-md-block d-none column-left">
            <div className="code-header">
              <div className="circle red"></div>
              <div className="circle yellow"></div>
              <div className="circle green"></div>
            </div>
            <div className="code-content">
              <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
                {codeString}
              </SyntaxHighlighter>
            </div>
          </div>
          <div className="col-md-7 col-12 column-right">
            <div className="content-result">
              <p className="personal-description">
                I'm an <b>IT Engineer</b> with an MBA in Business Management. My career began as a{" "}
                <b>Tech Entrepreneur</b> in 2005, during which I developed digital products for
                medium and large companies across diverse industries. From ticketing and music to
                software, virtual and augmented reality, metaverse, and even the food sector, I've
                had the privilege of contributing to exciting projects.
              </p>
              <div className="line-divider"></div>
              <ul>
                <li className="company">
                  <div className="company-logo">
                    <img
                      src={apmterminals}
                      name="apmterminals"
                      alt="APM Terminals"
                      title="APM Terminals"
                    />
                  </div>
                  <div className="company-description">
                    <h3 className="company-name">APM Terminals</h3>
                    <h4 className="company-rol">Lead Software Developer</h4>
                    <div className="dates-of-work">May 2023 - Currently</div>
                    <p className="company-details">
                      APM Terminals is a port operating company headquartered in The Hague,
                      Netherlands. A unit of Danish shipping company Maersk's Transport and
                      Logistics division, it manages container terminals and provides integrated
                      cargo and inland services.
                    </p>
                    <p className="company-development">
                      In charge of the platform development team to carry out operations, maintain,
                      and monitor the different terminals around the world.
                      <br /> We have developed an easy-to-implement platform, which connects to the
                      different terminals and can access information in real time. The great
                      challenge has been to be able to easily deploy in all terminals and align
                      ourselves with established security.
                      <br />
                      We have developed an infrastructure strategy that allows us to easily deploy
                      the terminal and thus be able to provide our internal and external clients
                      with what they need to view.
                    </p>
                    <label>Technologies and services:</label>
                    <div className="tags">
                      <span>React.JS</span>
                      <span>JavaScript</span>
                      <span>Node.JS</span>
                      <span>HTML</span>
                      <span>SCSS</span>
                      <span>CSS</span>
                      <span>MySQL</span>
                      <span>GitHub</span>
                      <span>CI/CD</span>
                      <span>Docker</span>
                      <span>Kubernetes</span>
                      <span>Azure</span>
                    </div>
                  </div>
                </li>
                <li className="company">
                  <div className="company-logo">
                    <img src={covrel} name="covrel" alt="COVREL" title="COVREL" />
                  </div>
                  <div className="company-description">
                    <h3 className="company-name">COVREL</h3>
                    <h4 className="company-rol">CEO & Co-Founder</h4>
                    <div className="dates-of-work">January 2020 - July 2023</div>
                    <p className="company-details">
                      Develop your own virtual environment to be able to connect, relate, expose,
                      and continue innovating without borders together with digital universes.
                    </p>
                    <p className="company-development">
                      With the aim of improving people's learning in their respective jobs, we have
                      developed different actions and products for large companies through
                      disruptive technology.
                      <br />
                      From my place, I have developed the company's position at a strategic level,
                      technological solutions that complemented the software, the expansion into
                      Europe and the creativity of the actions.
                      <br />
                      We have developed products for companies such as{" "}
                      <a href="https://wwww.ncr.com" target="_blank" rel="noreferrer">
                        NCR
                      </a>
                      ,{" "}
                      <a href="https://wwww.coca-cola.com" target="_blank" rel="noreferrer">
                        Coca-Cola
                      </a>
                      ,{" "}
                      <a href="https://wwww.burgerking.com" target="_blank" rel="noreferrer">
                        Burger King
                      </a>
                      ,{" "}
                      <a href="https://comic-con.com.ar/" target="_blank" rel="noreferrer">
                        Comic Con
                      </a>
                      , among others.
                    </p>
                    <label>Technologies and services:</label>
                    <div className="tags">
                      <span>Angular.JS</span>
                    </div>
                  </div>
                </li>
                <li className="company">
                  <div className="company-logo">
                    <img src={repeat} name="repeat" alt="Rep.Eat" title="Rep.Eat" />
                  </div>
                  <div className="company-description">
                    <h3 className="company-name">Rep.eat</h3>
                    <h4 className="company-rol">CTO & Partner</h4>
                    <div className="dates-of-work">May 2022 - April 2023</div>
                    <p className="company-details">
                      Improving Experience in the Gastronomic Industry.
                    </p>
                    <p className="company-development">
                      I have carried out the development of a platform to make collections and
                      payments with{" "}
                      <a href="https://www.mercadopago.com" target="_blank" rel="noreferrer">
                        MercadoPago
                      </a>{" "}
                      and{" "}
                      <a href="https://www.stripe.com" target="_blank" rel="noreferrer">
                        Stripe
                      </a>
                      , where each restaurant has its wallet connected and the system is in charge
                      of making the link.
                      <br />
                      On the other hand, I have developed different solutions as modules on the
                      platform, linking services such as SendGrid to carry out marketing campaigns.
                      <br />
                      Finally, an infrastructure strategy was designed with CI/CD to have each
                      environment running constantly, generating certain scripts to verify its
                      operation and provide a 99.998% active service.
                    </p>
                    <label>Technologies and services:</label>
                    <div className="tags">
                      <span>Angular.JS</span>
                      <span>JavaScript</span>
                      <span>Node.JS</span>
                      <span>HTML</span>
                      <span>SCSS</span>
                      <span>CSS</span>
                      <span>MongoDB</span>
                      <span>MySQL</span>
                      <span>MercadoPago</span>
                      <span>Stripe</span>
                      <span>SendGrid</span>
                      <span>GitLab</span>
                      <span>CI/CD</span>
                      <span>Docker</span>
                      <span>AWS</span>
                    </div>
                  </div>
                </li>
                <li className="company">
                  <div className="company-logo">
                    <img src={plugty} name="plugty" alt="Plugty" title="Plugty" />
                  </div>
                  <div className="company-description">
                    <h3 className="company-name">PLUGTY</h3>
                    <h4 className="company-rol">CTO & Co-Founder</h4>
                    <div className="dates-of-work">February 2016 - December 2019</div>
                    <p className="company-details">Software development company for companies.</p>
                    <p className="company-development">
                      In the search to provide solutions to companies, through Plugty we develop
                      products for different clients, focusing on providing an end-to-end service in
                      terms of planning, prototyping, development and commissioning.
                      <br />
                      Among our clients, we were able to develop the prototype of the white label
                      application for{" "}
                      <a href="https://www.n5now.com" target="_blank" rel="noopener noreferrer">
                        N5 Now
                      </a>
                      , the{" "}
                      <a
                        href="https://longboardgirlscrew.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Longboard Girls Crew
                      </a>{" "}
                      website, an integration for{" "}
                      <a href="https://wwww.burgerking.com" target="_blank" rel="noreferrer">
                        Burger King
                      </a>{" "}
                      between an application with virtual reality and a real-time analysis platform,
                      among other things.
                    </p>
                    <label>Technologies and services:</label>
                    <div className="tags">
                      <span>PHP</span>
                      <span>HTML</span>
                      <span>LESS</span>
                      <span>CSS</span>
                      <span>JavaScript</span>
                      <span>MySQL</span>
                      <span>MongoDB</span>
                      <span>Wordpress</span>
                      <span>SendGrid</span>
                      <span>GitHub</span>
                      <span>Docker</span>
                      <span>AWS</span>
                    </div>
                  </div>
                </li>
                <li className="company">
                  <div className="company-logo">
                    <img
                      src={topsifytrends}
                      name="topsifytrends"
                      alt="TOPSIFY Trends"
                      title="TOPSIFY Trends"
                    />
                  </div>
                  <div className="company-description">
                    <h3 className="company-name">TOPSIFY Trends</h3>
                    <h4 className="company-rol">CEO & Co-Founder</h4>
                    <div className="dates-of-work">January 2017 - January 2020</div>
                    <p className="company-details">
                      A platform developed in partnership with <b>Warner Music</b>, whose goal is to
                      allow music fans to discover, enjoy and experience music in a single space.
                    </p>
                    <p className="company-development">
                      After an important career developing <b>Eventdoo</b>, I was lucky enough to
                      meet with the VP of <b>Warner Music</b> while I was living in Uruguay. A
                      presentation about what could be done in the world of <b>Warner Music</b>{" "}
                      changed the story of what was to come.
                      <br />
                      We developed a white label platform in 8 countries in Latin America, where we
                      closed an agreement with{" "}
                      <a href="https://www.tigo.com/" target="_blank" rel="noopener noreferrer">
                        TIGO
                      </a>{" "}
                      to develop <b>TIGO Music</b> in 7 countries and our brand in Argentina.
                      <br />
                      Planning on how we could quickly launch platforms for our next clients made us
                      develop something quickly allowing us in a matter of minutes to have a
                      functional platform connected to a database of more than 5 thousand Warner
                      Music artists where you could find: upcoming shows, the latest songs, trivia,
                      enter entries, artist information and much more.
                      <br />
                      More than 2.5 million people accessed the different platforms designed to be
                      robust and allow users to listen to the best songs of the moment.
                    </p>
                    <label>Technologies and services:</label>
                    <div className="tags">
                      <span>PHP</span>
                      <span>Yii2</span>
                      <span>HTML</span>
                      <span>LESS</span>
                      <span>CSS</span>
                      <span>JavaScript</span>
                      <span>MySQL</span>
                      <span>Redis</span>
                      <span>SendGrid</span>
                      <span>GitHub</span>
                      <span>Docker</span>
                      <span>AWS</span>
                    </div>
                  </div>
                </li>
                <li className="company">
                  <div className="company-logo">
                    <img src={eventdoo} name="eventdoo" alt="Eventdoo" title="Eventdoo" />
                  </div>
                  <div className="company-description">
                    <h3 className="company-name">Eventdoo</h3>
                    <h4 className="company-rol">CEO & Co-Founder</h4>
                    <div className="dates-of-work">March 2016 - December 2016</div>
                    <p className="company-details">
                      Collaborative ticketing platform for artists, individuals, producers, brands
                      and companies can promote, market and manage their events.
                    </p>
                    <p className="company-development">
                      From the inception of the MVP to the release of the first version, we have
                      worked using PHP as the main language and Yii2 as the Framework.
                      <br />
                      In parallel, the entire platform was designed to have its MVC (Model, Views,
                      Controllers) connecting them to a MySQL relational database.
                      <br />
                      The design was thought so that we could have several platforms running
                      simultaneously, without complicating the launch of one, using a white label
                      model that gave us the practicality of quickly registering from our management
                      system and in seconds having the platform running with their styles, domains,
                      etc.
                      <br />
                      Our platform was what{" "}
                      <a href="https://www.movistar.com.ar" target="_blank" rel="noreferrer">
                        Movistar
                      </a>{" "}
                      Free Music was, which we have re-branded to launch what was Eventos En
                      Comunidad. At the same time, we developed the platform of the Government of
                      the City of Buenos Aires in the area of entrepreneurs. Another of the big
                      brands that joined us was Samsung through its own Samsung Studio.
                    </p>
                    <label>Technologies and services:</label>
                    <div className="tags">
                      <span>PHP</span>
                      <span>Yii2</span>
                      <span>HTML</span>
                      <span>LESS</span>
                      <span>CSS</span>
                      <span>JavaScript</span>
                      <span>MySQL</span>
                      <span>MercadoPago</span>
                      <span>SendGrid</span>
                      <span>GitHub</span>
                      <span>Docker</span>
                      <span>AWS</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
